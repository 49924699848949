import * as React from 'react';
import './App.css';

// MUI Imports
import { ThemeProvider, createTheme } from '@mui/material/styles';

// EXTERNAL PACKAGES
import { Switch, Route, Redirect } from 'react-router-dom';

// CUSTOM COMPONENTS
import Sidx64 from './components/Sidx64';

// CONTEXT
import { UserProvider } from './context/userContext';
import { getDesignTokens, selectedTheme } from './context/themeContext';
import { ThemeContext } from './context/themeContext';

// CUSTOM FONTS
require('./FontImports');

function App() {
  // State hook for website theme value (light or dark)
  const [mode, setMode] = React.useState(selectedTheme());

  // A hook to ensure that a Hash Redirect works even when coming in from
  // external sites. This helps to create direct HashLinks to any section on the page
  React.useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element)
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, []);

  // UseMemo for setting theme as per user preference.
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        // user selected mode
        let newMode;
        setMode((prevMode) => {
          prevMode === 'light' ? (newMode = 'dark') : (newMode = 'light');

          // store user's theme choice in storage
          localStorage.setItem('theme', newMode);

          return newMode;
        });
      },
    }),
    []
  );

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <Switch>
            <Route exact path='/' component={Sidx64} />
            <Route render={() => <Redirect to='/' />} />
          </Switch>
        </UserProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
