import * as React from 'react';

// MUI Imports
import Paper from '@mui/material/Paper';

// EXTERNAL PACKAGES
import { ParticleAnimation } from './ParticleAnimation/ParticleAnimation';

// CONTEXT
import { UserContext } from '../context/userContext';

// CUSTOM COMPONENTS
import NavBar from './NavBar';
import IntroSlab from './IntroSlab';
import AboutSlab from './AboutSlab';
import RolesSlab from './RolesSlab';
import ContactDialog from './ContactDialog';
import TechSlab from './TechSlab';

const styleSet = {
  root: {
    boxShadow: 'none',
    margin: 0,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '0',
    background: 'transparent',
  },
};

const Sidx64 = () => {
  // Hook for Contact Dialog
  const [openContactDialog, setopenContactDialog] = React.useState(false);

  // User context
  const data = React.useContext(UserContext).data;

  // Set Page Title to the user's preferred site name
  React.useEffect(() => {
    if (data.siteName) document.title = data.siteName;
    else document.title = 'My Portfolio';
  }, [data.siteName]);

  return (
    <div className='scroll-container'>
      <div style={{ position: 'absolute', zIndex: '-1' }}>
        <ParticleAnimation />
      </div>
      <NavBar
        sx={{ marginBottom: '0rem', color: 'theme.primary' }}
        contactDialog={setopenContactDialog}
      />
      <Paper elevation={0} sx={styleSet.root}>
        <IntroSlab />
      </Paper>
      <Paper elevation={0} sx={styleSet.root}>
        <AboutSlab />
      </Paper>
      <Paper elevation={0} sx={styleSet.root}>
        <TechSlab />
      </Paper>
      <Paper elevation={0} sx={styleSet.root}>
        <RolesSlab />
      </Paper>
      <ContactDialog
        fullWidth={true}
        contactDialog={setopenContactDialog}
        isContactDialogOpen={openContactDialog}
      />
    </div>
  );
};

export default Sidx64;
