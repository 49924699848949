import * as React from 'react';

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          type: 'light',
          primary: {
            main: '#e65100',
          },
          secondary: {
            main: '#1565c0',
          },
          error: {
            main: '#c62828',
          },
          warning: {
            main: '#ef6c00',
          },
          info: {
            main: '#42a5f5',
          },
          success: {
            main: '#00c853',
          },
          text: {
            linkText: '#333',
          },
          // background: {
          //   default: '#eee',
          // },
        }
      : {
          // palette values for dark mode
          type: 'dark',
          primary: {
            main: '#e65100',
          },
          secondary: {
            main: '#f50057',
          },
          text: {
            primary: '#eee',
            secondary: '#fb8c00',
            hint: '#616161',
            light: '#ddd',
            extraLight: '#888',
            linkText: '#ddd',
          },
          background: {
            default: '#101010',
            paper: '#191919',
          },
        }),
  },
  typography: {
    button: {
      fontFamily: 'Sora',
    },
    h1: {
      fontFamily: 'Sora',
      fontWeight: 700,
    },
    fontFamily: 'Sora',
    fontWeightLight: 600,
  },
});

export const ThemeContext = React.createContext({
  toggleColorMode: () => {},
});

export const selectedTheme = () => {
  if (localStorage.getItem('theme')) {
    return localStorage.getItem('theme');
  } else return 'light';
};
