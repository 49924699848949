const data = {
  siteName: 'Bit64 - Sid',
  siteSections: [
    {
      sectionName: 'Home',
      sectionMaterialIcon: 'Cottage',
    },
    {
      sectionName: 'About',
      sectionMaterialIcon: 'Cottage',
    },
    {
      sectionName: 'Tech',
      sectionMaterialIcon: 'Cottage',
    },
    {
      sectionName: 'Work',
      sectionMaterialIcon: 'Cottage',
    },
    {
      sectionName: 'Contact',
      sectionMaterialIcon: 'Cottage',
    },
  ],
  careerOverview: {
    careerStartYear: '2014',
    totalYears: '8',
  },
  profile: {
    firstName: 'Siddharth',
    lastName: 'Shanbhogue',
    petName: 'Sid',
    fullName: 'Siddharth Shanbhogue',
    email: 'siddharthx64@gmail.com',
    personalDescriptionVerbs: [
      'Software Developer',
      'FrontEnd Enthusiast',
      'Cloud Engineer',
      'Drone Builder',
      'Drone Pilot',
    ],
    personalDescriptionLine:
      'I am a Fullstack Developer, FrontEnd Enthusiast, Cloud Engineer & a Drone Builder',
    selfDescription: [
      "Hi, my name is Siddharth. My love for computers started way back in the 1990's and I have been hooked ever since, and web development has a special place in my heart. I am also a Cloud Engineer, focused on AWS and Azure.",
      "When I am not working on web development projects, you'll find me reading fiction, learning something new, or working on FPV drones. ",
    ],
    profileLinks: [
      {
        platform: 'LinkedIn',
        link: 'https://www.linkedin.com/in/siddharthx64',
      },
      {
        platform: 'Instagram',
        link: 'https://www.instagram.com/sidx64',
      },
      {
        platform: 'Twitter',
        link: 'https://twitter.com/siddharthx64',
      },
      {
        platform: 'GitHub',
        link: 'https://github.com/sidx64',
      },
      {
        platform: 'HackerRank',
        link: 'https://www.hackerrank.com/siddharthx64',
      },
      {
        platform: 'CodeWars',
        link: 'https://www.codewars.com/users/sidx64',
      },
    ],
  },
  connectLinks: [
    {
      method: 'LinkedIn',
      link: 'https://www.linkedin.com/in/siddharthx64',
    },
    {
      method: 'Email',
      link: 'https://www.linkedin.com/in/siddharthx64',
    },
    {
      method: 'Phone',
      link: '(+91) 9686-578-238',
    },
    {
      method: 'Twitter',
      link: 'https://twitter.com/siddharthx64',
    },
  ],
  contactInfo: {
    personalEmail: 'siddharth.x64@gmail.com',
    mobileCountryCode: '+91',
    mobileNumber: '9686578238',
  },
  resumeContext: {
    professionalSummary:
      'Meticulous web developer with over seven years of collective experience in web development, JavaScript, Python, Cloud, and related technologies and a passion for responsive, mobile-first web design, looking for a challenging environment to work, learn and grow in.',
    synopsis: [
      'A competent, passionate, and skilled professional with 7+ years of collective experience across JavaScript, Python, Cloud, Containerization, and related technologies.',
      'Proficient in front end development in JavaScript (ES6, React, jQuery), HTML5, CSS3, and more, with 6+ years of web-development experience, proficient in working with multiple front-end UI design systems such as Bootstrap, Material UI, Flexbox, and others.',
      'Working knowledge and experience of backend API design using python (Django).',
      'A fast learner and an effective communicator with excellent Interpersonal and team-management abilities. Keenly Interested in collaborative work, solving problems, developing scalable solutions, and resolving roadblocks.',
      'Presently associated with Cerner Healthcare Corporation as a Cloud Software Engineer Level 4 (Lead).',
    ],
    favTechnologies: [
      'JS (ES6+)',
      'React',
      'React-Spring',
      'Material UI',
      'TypeScript',
      'AWS',
      'Azure',
    ],
  },
  skillSet: {
    programming: [
      {
        technology: 'Python',
        iconName: require('./images/techStack/pythonCol.png'),
        selfRatingOutOf5: '3',
      },
      {
        technology: 'ES6',
        iconName: require('./images/techStack/esIconCol.png'),
        selfRatingOutOf5: '4',
      },
      {
        technology: 'Type Script',
        iconName: require('./images/techStack/typeScriptCol.png'),
        selfRatingOutOf5: '2',
      },
    ],
    'web development': [
      {
        technology: 'HTML5',
        iconName: require('./images/techStack/html5Col.png'),
        iconWidth: 64,
        selfRatingOutOf5: '2',
      },
      {
        technology: 'CSS3',
        iconName: require('./images/techStack/css3Col.png'),
        selfRatingOutOf5: '2',
      },
      {
        technology: 'React JS',
        iconName: require('./images/techStack/react.png'),
        selfRatingOutOf5: '3.5',
      },
      {
        technology: 'jQuery',
        iconName: require('./images/techStack/jqueryCol.png'),
        selfRatingOutOf5: '2.5',
      },
      {
        technology: 'BootStrap',
        iconName: require('./images/techStack/bstrapIconCol.png'),
        selfRatingOutOf5: '3',
      },
      {
        technology: 'Material UI',
        iconName: require('./images/techStack/mui.png'),
        selfRatingOutOf5: '3.5',
      },
      {
        technology: 'Django',
        iconName: require('./images/techStack/djangoCol.png'),
        selfRatingOutOf5: '2',
      },
    ],
    cloud: [
      {
        technology: 'S3',
        iconName: require('./images/techStack/Arch_Amazon-Simple-Storage-Service_64.png'),
        selfRatingOutOf5: '3',
      },
      {
        technology: 'API Gateway',
        iconName: require('./images/techStack/Arch_Amazon-API-Gateway_64.png'),
        selfRatingOutOf5: '3',
      },
      {
        technology: 'Cloud Front',
        iconName: require('./images/techStack/Arch_Amazon-CloudFront_64.png'),
        selfRatingOutOf5: '3',
      },
      {
        technology: 'Lambda',
        iconName: require('./images/techStack/Arch_AWS-Lambda_64.png'),
        selfRatingOutOf5: '3',
      },
      {
        technology: 'Azure Functions',
        iconName: require('./images/techStack/Azure_Functions.png'),
        selfRatingOutOf5: '2',
      },
      {
        technology: 'Azure App Service',
        iconName: require('./images/techStack/Azure_App_Service.png'),
        selfRatingOutOf5: '2',
      },
    ],
    CICD: [
      {
        technology: 'Jenkins',
        iconName: require('./images/techStack/jenkins.png'),
        domain: 'automation',
        selfRatingOutOf5: '3',
      },
      {
        technology: 'AWS CodeBuild',
        iconName: require('./images/techStack/Arch_AWS-CodeBuild_64.png'),
        domain: 'automation',
        selfRatingOutOf5: '3',
      },
      {
        technology: 'Docker',
        iconName: require('./images/techStack/docker.png'),
        domain: 'containerisation',
        selfRatingOutOf5: '3',
      },
    ],
    tools: [
      {
        technology: 'VS Code',
        iconName: require('./images/techStack/vscode.png'),
        domain: 'IDE',
        selfRatingOutOf5: '4',
      },
      {
        technology: 'Vim',
        iconName: require('./images/techStack/vim.png'),
        domain: 'text editor',
        selfRatingOutOf5: '3',
      },
    ],
  },
  workExperience: [
    {
      chronology: 0,
      role: 'Systems Intern',
      jobDomain: 'Infrastructure Engineering',
      startYear: '2014',
      startMonth: 'January',
      endYear: '2014',
      endMonth: 'June',
      current: false,
      organisation: 'Cerner Healthcare Corporation',
      homeBase: 'Bangalore, KA, India',
      description: [
        'Worked as an intern for Infrastructure Service Delivery teams.',
        'Worked on BaSh-based shell scripts and automation projects to reduce manual effort.',
        'Familiarized myself with RedHat Enterprise Linux, virtualization & private DataCenter technology based on VMWare ESXi Infrastructure.',
      ],
    },
    {
      chronology: 1,
      role: 'Systems Engineer',
      jobDomain: 'Infrastructure Engineering',
      startYear: '2014',
      startMonth: 'January',
      endYear: '2015',
      endMonth: 'January',
      current: false,
      organisation: 'Cerner Healthcare Corporation',
      homeBase: 'Bangalore, KA, India',
      description: [
        'Worked on data center infrastructure systems (Linux and Windows) as part of the Service Delivery Backend team.',
        'Developed a clear understanding of Linux servers and BaSh scripting.',
        'Worked on SAN Multipath issue remediation, server builds and reclaims.',
      ],
    },
    {
      chronology: 2,
      role: 'Software Engineer II',
      jobDomain: 'Cloud Engineering',
      startYear: '2015',
      startMonth: 'February',
      endYear: '2017',
      endMonth: 'May',
      current: false,
      organisation: 'Cerner Healthcare Corporation',
      homeBase: 'Bangalore, KA, India',
      description: [
        'Worked on OpenStack based private data center cloud systems.',
        'Picked up Cloud Engineering concepts and principles.',
        'Gained more web development exposure.',
      ],
    },
    {
      chronology: 3,
      role: 'Software Engineer III',
      jobDomain: 'Cloud Engineering',
      startYear: '2017',
      startMonth: 'June',
      endYear: '2019',
      endMonth: 'May',
      current: false,
      organisation: 'Cerner Healthcare Corporation',
      homeBase: 'Bangalore, KA, India',
      description: [
        'Worked in the AWS cloud domain on Python and Boto3.',
        'Gained working understanding of AWS services.',
        'Worked on web development projects with BootStrap, JS, jQuery and Django ReST APIs.',
      ],
    },
    {
      chronology: 4,
      role: 'Software Engineer IV',
      jobDomain: 'Cloud Engineering',
      startYear: '2019',
      startMonth: 'June',
      endYear: '2022',
      endMonth: 'May',
      current: true,
      organisation: 'Cerner Healthcare Corporation',
      homeBase: 'Bangalore, KA, India',
      description: [
        'Leading a dedicated 4-member team on multiple projects in AWS & web development.',
        'Working on web development projects in react, hosted on AWS Cloud Services.',
        'Mentoring & helping new team members get up-to-speed on current goings on.',
      ],
    }, {
      chronology: 5,
      role: 'Lead Software Engineer',
      jobDomain: 'FullStack Development',
      startYear: '2022',
      startMonth: 'May',
      endYear: '',
      endMonth: '',
      current: true,
      organisation: 'Providence Global Center',
      homeBase: 'Hyderabad, TS, India',
      description: [
        'Leading a dedicated 4-member team on multiple Application Development initiatives.',
        'Designing fault tolerant architectures for complex application requirements, leveraging the power of Cloud Services',
        'Working on NodeJS/React stack with Development, Deployment & DevOps on Kubernetes, Azure & ADO.',
        'Mentoring & helping new team members, and setting up standards for development/DX in an agile mindset.',
      ],
    },
  ],
  notableProjects: [
    {
      id: 1,
      name: 'Altitude',
      techStack: ['ReactJS', 'Material UI', 'Python', 'AWS', 'Serverless'],
      description:
        'A web-based cloud tenant management system designed to provide end-to-end tenant management capabilities for the organization’s cloud requirements. This app caters to a global user base, and is designed to handle new tenant requests, manage existing cloud tenant accounts, provide assistive workflows for with financial planning and forecasting, and more.',
    },
    {
      id: 2,
      name: 'Cerner Patents Portal',
      techStack: [
        'ReactJS',
        'Material UI',
        'Python',
        'Django Rest Framework',
        'PostgreSQL',
      ],
      description:
        'An internal web application that facilitates the company’s goal of fostering innovation and provides functions, workflows and insights for idea generation, patent tracking and more. The application provides the global employee base with a platform to submit their ideas and to pursue possible patent opportunities.',
    },
    {
      id: 3,
      name: 'India Associate Portal',
      techStack: [
        'HTML',
        'CSS',
        'JavaScript',
        'jQuery',
        'BootStrap',
        'Python',
        'Django Rest Framework',
        'PostgreSQL',
      ],
      description:
        'An internal services portal for the organization’s functional services, as part of an initiative to improve the work environment for associates. Initially based on LAMP, the web-app was redesigned and rebuilt a responsive, RESTful API-driven approach. The portal continues to be used by over 6000 associates based out of India.',
    },
    {
      id: 4,
      name: 'Acronyms App',
      techStack: [
        'HTML',
        'CSS',
        'JavaScript',
        'jQuery',
        'BootStrap',
        'Python',
        'Django Rest Framework',
        'PostgreSQL',
      ],
      description:
        'A simple yet immensely functional app for crowd-sourcing technical terminology in use within the organization. The app aims to help associates better understand the terms and linguistic jargons used by their teams. The app has over 3500 acronyms that are in use within the organization and associates keep adding new ones every day.',
    },
    {
      id: 5,
      name: 'Infinity Platform',
      techStack: [
        'Mesos',
        'Marathon',
        'Docker',
        'GitLab',
        'Jenkins',
        'HAProxy',
        'PostgreSQL',
      ],
      description:
        'A containerization platform designed to provide app hosting, CI/CD pipelines, and more. The platform consists of multiple isolated servers that form database (PGSQL) and container management clusters (Mesos/Marathon/Zookeeper). Load balancing and routing is also handled by the system using HAProxy. The platform hosts over 15 different applications with a containerized (n+2) approach.',
    },
  ],
};
export default data;
