import { useSpring } from 'react-spring';

// Animation Spring
export const useFadeInFromRightEffect = (ref, duration = 500, delay = 100) => {
  const spring = useSpring({
    from: {
      opacity: 0,
      x: 100,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    delay: delay,
    config: { duration: duration },
    ref: ref,
  });

  return spring;
};
export const useFadeInFromLeftEffect = (ref, duration = 500, delay = 100) => {
  const spring = useSpring({
    from: {
      opacity: 0,
      x: -50,
    },
    to: {
      opacity: 1,
      x: 0,
    },

    delay: delay,
    config: { duration: duration },
    ref: ref,
  });

  return spring;
};

export const useFadeInEffect = (ref, duration = 500, delay = 100) => {
  const spring = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },

    delay: delay,
    config: { duration: duration },
    ref: ref,
  });

  return spring;
};
