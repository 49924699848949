import * as React from 'react';
import data from '../assets/data';

export const UserContext = React.createContext(data);

export const UserProvider = ({ children }) => {
  const [userData] = React.useState(data);

  return (
    <UserContext.Provider
      value={{
        data,
      }}>
      {children}
    </UserContext.Provider>
  );
};
