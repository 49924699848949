import * as React from 'react';
import { Typography, Grid } from '@mui/material';

// REACT SPRING ANIMATION HOOKS
import { animated, useSpringRef, useChain } from 'react-spring';
import { useFadeInEffect } from '../animations/springAnimationHooks';

// EXTERNAL PACKAGES
import { Waypoint } from 'react-waypoint';

// CUSTOM COMPONENTS
import TechStack from './TechStack';

// CONTEXT
import { UserContext } from '../context/userContext';
// import { useTheme } from '@mui/styles';

export default function TechSlab() {
  // const theme = useTheme();

  // State Hook for Animation Trigger
  const [startAnimation, setStartAnimation] = React.useState(false);

  const data = React.useContext(UserContext).data;

  const styleSet = {
    section: {
      fontFamily: 'libre baskerville',
      borderRadius: '0',
    },
    sectionDescriptionText: {
      fontFamily: 'libre baskerville',
      marginTop: { xs: '0rem', sm: '0.2rem' },
      fontSize: { xs: '0.8rem', sm: '1.1rem', md: '1.3rem' },
      textAlign: { xs: 'center', sm: 'left', md: 'left' },
    },
    heading: {
      fontWeight: 'bold',
      fontFamily: 'libre baskerville',
      fontSize: { xs: '1.6rem', sm: '2.5rem', md: '3rem' },
      textAlign: { xs: 'center', sm: 'left', md: 'left' },
    },
  };

  // References for Animation of specific components
  const refHeadingAnim = useSpringRef(),
    refNameAnim = useSpringRef(),
    refSelfDescAnim = useSpringRef();

  // create animation styles for each animation reference
  const styleHeadingAnim = useFadeInEffect(refHeadingAnim),
    styleNameAnim = useFadeInEffect(refNameAnim),
    styleSelfDescriptionAnim = useFadeInEffect(refSelfDescAnim);

  // Chain selected animations
  useChain(
    startAnimation ? [refHeadingAnim, refNameAnim, refSelfDescAnim] : []
  );

  return (
    <section id='Tech' sx={styleSet.section}>
      <Waypoint onEnter={() => setStartAnimation(true)} />
      <Grid container alignItems={'center'} justifyContent={'center'} style={{ minHeight: '100vh' }}>
        <Grid item xs={11} sm={10} md={9} lg={7}>
          <Grid item xs={12} md={10}>
            <animated.div style={styleHeadingAnim}>
              <Typography sx={styleSet.heading}>I love tech!</Typography>
            </animated.div>
          </Grid>
          <Grid item xs={12}>
            <animated.div style={styleHeadingAnim}>
              <Typography sx={styleSet.sectionDescriptionText}>
                Over the past {data.careerOverview.totalYears} years, I've
                worked with multiple languages, frameworks, technologies &
                platforms. While my interests lie in
                {data.resumeContext.favTechnologies.map((tech, idx) => {
                  if (idx + 1 === data.resumeContext.favTechnologies.length)
                    return ' & ' + tech;
                  else if (idx === 0) return ' ' + tech;
                  else return ', ' + tech;
                })}
                , I have experience with the following technologies:
              </Typography>
            </animated.div>
          </Grid>
          <Grid item sx={{ pt: 2, pb: 2 }} xs={12}>
            <animated.div style={styleHeadingAnim}>
              <TechStack />
            </animated.div>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
}
