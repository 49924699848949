import * as React from 'react';

// MATERIAL UI
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import Slide from '@mui/material/Slide';

// ICONS
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

// Transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function MaxWidthDialog(props) {
  // Hooks
  const theme = useTheme();

  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Theme
  const styleSet = {
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      // marginLeft: 64,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    contactIcons: {
      fontSize: '2rem',
      color: theme.palette.primary,
      transition: 'ease-in-out 0.25s',
    },
    contactButtons: {
      transition: 'cubic-bezier(.17,.67,.83,.67) 1.5s',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        background: '-webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        WebkitTextFillColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
        background: '-webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        WebkitTextFillColor: 'transparent',
      },
    },
    contactIconSelected: {
      fontSize: '2rem',
      color: theme.palette.primary,
    },
    contactCard: {
      width: '100%',
    },
    cursiveFont: {
      fontFamily: 'Dancing Script',
      fontSize: '2.6rem',
      color: theme.palette.text,
    },
    regularFont: {
      fontFamily: 'sora',
      color: theme.palette.text,
    },
    standardCursiveText: {
      fontSize: '1.5rem',
    },
    dialogStyle: {
      p: '0rem',
    },
  };

  const handleClose = () => {
    props.contactDialog(false);
  };

  const handleClickOpen = (event) => (id) => {
    if (event === 'mailto') {
      window.location.href = 'mailto:sid.rvce@gmail.com';
    } else if (event === 'instagram') {
      window.open('https://instagram.com/venomxfpv', '_fpvInstagram');
    } else if (event === 'linkedin') {
      window.open('https://www.linkedin.com/in/siddharthx64/', '_linkedin');
    } else if (event === 'twitter') {
      window.open('https://twitter.com/siddharthx64', '_twitter');
    }
  };

  // const ConnectButtons = () => {
  //   let buttonsList = [];
  // };

  return (
    <React.Fragment>
      <Dialog
        TransitionComponent={Transition}
        // fullScreen={fullScreen}
        transitionDuration={500}
        sx={styleSet.dialogStyle}
        fullWidth={props.fullWidth}
        open={props.isContactDialogOpen}
        maxWidth={'sm'}
        onClose={handleClose}>
        <DialogTitle
          sx={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>
          <Typography sx={styleSet.cursiveFont}>Connect with me!</Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            p: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
          }}>
          <Box
            noValidate
            component='div'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
            }}>
            <Box display='flex' p={1} flexWrap='wrap'>
              <Typography sx={styleSet.regularFont} gutterBottom>
                You can reach out to me in any of the following ways!
              </Typography>
            </Box>
            <Box
              display='flex'
              flexWrap='wrap'
              justifyContent='left'
              alignContent='left'>
              <Box m={1}>
                <Button
                  sx={styleSet.contactButtons}
                  color='inherit'
                  onClick={handleClickOpen('mailto')}>
                  <EmailIcon sx={styleSet.contactIcons} />
                </Button>
              </Box>

              <Box m={1}>
                <Button
                  sx={styleSet.contactButtons}
                  color='inherit'
                  onClick={handleClickOpen('linkedin')}>
                  <LinkedInIcon sx={styleSet.contactIcons} />
                </Button>
              </Box>

              <Box m={1}>
                <Button
                  sx={styleSet.contactButtons}
                  color='inherit'
                  onClick={handleClickOpen('instagram')}>
                  <InstagramIcon sx={styleSet.contactIcons} />
                </Button>
              </Box>

              <Box m={1}>
                <Button
                  sx={styleSet.contactButtons}
                  color='inherit'
                  onClick={handleClickOpen('twitter')}>
                  <TwitterIcon sx={styleSet.contactIcons} />
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: theme.palette.primary.main }}>
          <Button sx={{ color: 'white' }} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
