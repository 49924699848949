import * as React from 'react';

// MUI Imports
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

// EXTERNAL PACKAGES
import { HashLink } from 'react-router-hash-link';

// HOOKS
import { animated, useSpringRef, useChain } from 'react-spring';
import { useFadeInFromRightEffect } from '../animations/springAnimationHooks';

//ICONS
import MenuIcon from '@mui/icons-material/Menu';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import CottageIcon from '@mui/icons-material/Cottage';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HandymanIcon from '@mui/icons-material/Handyman';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import FeedIcon from '@mui/icons-material/Feed';
import MemoryIcon from '@mui/icons-material/Memory';

// CONTEXT
import { ThemeContext } from '../context/themeContext';
import { UserContext } from '../context/userContext';

// ASSETS
import ResumeDoc from '../assets/resume/siddharthShanbhogue2021.pdf';
import SwitchClick from '../assets/audio/switch.wav';

// DONE: #20 NavBar Buttons must links to specific sections of the page
// DONE: #21 NavBar Buttons must also show URL changes
// DONE: #22 Implement React Router
// DONE: #23 URL change must activate scroll to specific section

// Use the Web Audio API to activate a sound
const soundSwitchClick = new Audio(SwitchClick);

const ResponsiveAppBar = (props) => {
  const theme = useTheme();
  const data = React.useContext(UserContext).data;

  // References for Animation of specific components
  const refNavTitleAnim = useSpringRef(),
    refMenuBtnAnim1 = useSpringRef(),
    refMenuBtnAnim2 = useSpringRef(),
    refMenuBtnAnim3 = useSpringRef(),
    refMenuBtnAnim4 = useSpringRef(),
    refMenuBtnAnim5 = useSpringRef(),
    refThemeBtnAnim = useSpringRef(),
    refResumeBtnAnim = useSpringRef();

  // create animation styles for each animation reference
  const styleNavTitleAnim = useFadeInFromRightEffect(refNavTitleAnim);

  // Menu Button Animation Styles
  const styleMenuBtnAnim = [
    useFadeInFromRightEffect(refMenuBtnAnim1, 100, 50),
    useFadeInFromRightEffect(refMenuBtnAnim2, 100, 50),
    useFadeInFromRightEffect(refMenuBtnAnim3, 100, 50),
    useFadeInFromRightEffect(refMenuBtnAnim4, 100, 50),
    useFadeInFromRightEffect(refMenuBtnAnim5, 100, 50),
  ];

  // Animation styles for Theme Change Button
  const styleThemeBtnAnim = useFadeInFromRightEffect(refThemeBtnAnim);

  // Animation styles for Resume Button
  const styleResumeBtnAnim = useFadeInFromRightEffect(refResumeBtnAnim);

  // Chain selected animations
  useChain([
    refNavTitleAnim,
    refMenuBtnAnim1,
    refMenuBtnAnim2,
    refMenuBtnAnim3,
    refMenuBtnAnim4,
    refMenuBtnAnim5,
    refResumeBtnAnim,
    refThemeBtnAnim,
  ]);

  // Additional CSS styling that will override theme styling
  const styleSet = {
    MDAppBarName: {
      fontFamily: 'dancing script',
      fontSize: '2.2rem',
      fontWeight: 700,
      mr: 2,
      display: { xs: 'none', md: 'flex' },
    },
    XSAppBarName: {
      fontFamily: 'dancing script',
      fontSize: '2.2rem',
      fontWeight: 700,
      flexGrow: 1,
      pr: '1.2rem',
      display: { xs: 'flex', md: 'none' },
    },
    linkTag: {
      textDecoration: 'none',
      color: 'white',
    },
    linkTextTag: {
      textDecoration: 'none',
      color: theme.palette.text.linkText,
    },
    navMenuItems: {
      textDecoration: 'none',
      fontFamily: 'dancing script',
      fontSize: '1.8rem',
      fontWeight: 700,
    },
    navMobileMenuItems: {
      fontFamily: 'dancing script',
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    titleTag: {
      fontFamily: 'sora',
      fontWeight: 400,
      fontSize: '2rem',
      margin: '0.2rem',
    },
  };

  // hook for tracking theme changes
  const colorMode = React.useContext(ThemeContext);

  // hook for nav menu icon
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  // handler for nav menu open
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  // handler for nav menu close
  const handleCloseNavMenu = (e) => {
    if (e.target.innerText === 'Contact') {
      props.contactDialog(true);
    }
    setAnchorElNav(null);
  };

  // theme change handler
  const handleThemeChange = () => {
    // Play Sound
    soundSwitchClick.play();
    colorMode.toggleColorMode();
  };

  // Get the right icon for the nav mobile menu
  const getIconListItem = (componentName) => {
    const components = {
      Home: CottageIcon,
      About: AccountBoxIcon,
      Work: HandymanIcon,
      Tech: MemoryIcon,
      Hobbies: SportsEsportsIcon,
      Contact: AlternateEmailIcon,
    };

    let ComponentIcon = components[componentName];
    return (
      <ListItemIcon>
        <ComponentIcon style={styleSet.linkTextTag} fontSize='small' />
      </ListItemIcon>
    );
  };

  return (
    <AppBar
      enableColorOnDark
      sx={{ backgroundColor: theme.palette.primary }}
      elevation={0}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <HashLink style={styleSet.linkTag} to='/home'>
            <animated.div style={styleNavTitleAnim}>
              <Typography
                variant='h6'
                noWrap
                component='div'
                sx={styleSet.MDAppBarName}>
                <span style={styleSet.titleTag}>{'<'}</span>
                {data.profile.fullName}
                <span style={styleSet.titleTag}>{'/>'}</span>
              </Typography>
            </animated.div>
          </HashLink>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <HashLink style={styleSet.linkTag} to='/'>
              <Typography
                variant='h6'
                noWrap
                component='div'
                sx={styleSet.XSAppBarName}>
                <animated.div style={styleNavTitleAnim}>
                  <span style={styleSet.titleTag}>{'< '}</span>
                  {data.profile.petName}
                  <span style={styleSet.titleTag}>{' />'}</span>
                </animated.div>
              </Typography>
            </HashLink>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <animated.div style={styleMenuBtnAnim[0]}>
              <IconButton
                size='large'
                color='inherit'
                aria-label='Menu'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                disableTouchRipple
                onClick={handleOpenNavMenu}>
                <MenuIcon />
              </IconButton>
            </animated.div>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              {data.siteSections.map((section) => (
                <HashLink
                  style={styleSet.linkTextTag}
                  key={section.sectionName}
                  smooth
                  to={'/#' + section.sectionName}>
                  <MenuItem
                    key={section.sectionName}
                    onClick={handleCloseNavMenu}>
                    {getIconListItem(section.sectionName)}
                    <Typography sx={styleSet.navMobileMenuItems}>
                      {section.sectionName}
                    </Typography>
                  </MenuItem>
                </HashLink>
              ))}
              <MenuItem onClick={handleCloseNavMenu}>
                <ListItemIcon>
                  <FeedIcon style={styleSet.linkTextTag} fontSize='small' />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={styleSet.navMobileMenuItems}>
                    <a
                      style={styleSet.linkTextTag}
                      target='_blank'
                      href={ResumeDoc}
                      download='siddharthShanbhogue2021.pdf'
                      rel='noreferrer'>
                      Resume
                    </a>
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  sx={{
                    p: 0,

                    '&:hover': { backgroundColor: 'transparent !important' },
                  }}
                  style={styleSet.navMobileMenuItems}
                  onClick={handleThemeChange}>
                  {theme.palette.mode === 'dark' ? (
                    <>
                      <ListItemIcon>
                        <LightModeIcon
                          style={styleSet.linkTextTag}
                          fontSize='small'
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={styleSet.navMobileMenuItems}>
                          Light Theme
                        </Typography>
                      </ListItemText>
                    </>
                  ) : (
                    <>
                      <ListItemIcon>
                        <DarkModeIcon
                          style={styleSet.linkTextTag}
                          fontSize='small'
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={styleSet.navMobileMenuItems}>
                          Dark Theme
                        </Typography>
                      </ListItemText>
                    </>
                  )}
                </IconButton>
              </MenuItem>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            {data.siteSections.map((section, idx) => (
              <HashLink
                key={section.sectionName}
                smooth
                style={styleSet.linkTag}
                to={'/#' + section.sectionName}>
                <animated.div
                  key={section.sectionName}
                  style={styleMenuBtnAnim[idx]}>
                  <MenuItem
                    component='button'
                    key={section.sectionName}
                    onClick={handleCloseNavMenu}>
                    <Typography sx={styleSet.navMenuItems} textAlign='center'>
                      {section.sectionName}
                    </Typography>
                  </MenuItem>
                </animated.div>
              </HashLink>
            ))}

            <animated.div style={styleResumeBtnAnim}>
              <MenuItem component='button' key='resume'>
                <Typography sx={styleSet.navMenuItems} textAlign='center'>
                  <a
                    sx={styleSet.linkTag}
                    target='_blank'
                    href={ResumeDoc}
                    download='siddharthShanbhogue2021.pdf'
                    rel='noreferrer'>
                    Resume
                  </a>
                </Typography>
              </MenuItem>
            </animated.div>

            <animated.div style={styleThemeBtnAnim}>
              <IconButton onClick={handleThemeChange} color='inherit'>
                {theme.palette.mode === 'dark' ? (
                  <Tooltip key='lightMode' title='Switch to Light Theme'>
                    <LightModeIcon
                      key='lightModeIcon'
                      sx={{ p: 0.8 }}
                      fontSize='medium'
                    />
                  </Tooltip>
                ) : (
                  <Tooltip key='darkMode' title='Switch to Dark Theme'>
                    <DarkModeIcon
                      key='darkModeIcon'
                      sx={{ p: 0.8 }}
                      fontSize='medium'
                    />
                  </Tooltip>
                )}
              </IconButton>
            </animated.div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
