import * as React from 'react';

// MATERIAL UI
import { useTheme } from '@mui/material/styles';
import { Typography, Grid, Box } from '@mui/material';
import { Tabs, Tab } from '@mui/material';

// REACT SPRING ANIMATION HOOKS
import { animated, useSpringRef, useChain } from 'react-spring';
import {
  useFadeInFromRightEffect,
  useFadeInEffect,
} from '../animations/springAnimationHooks';

// MATERIAL ICONS
import CheckIcon from '@mui/icons-material/Check';

// EXTERNAL PACKAGES
import { Waypoint } from 'react-waypoint';

// CONTEXT
import { UserContext } from '../context/userContext';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 1, pl: 2, minWidth: '100%' }}>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function RolesSlab() {
  const theme = useTheme();
  const data = React.useContext(UserContext).data;

  // State Hook for Animation Trigger
  const [startAnimation, setStartAnimation] = React.useState(false);

  const [value, setValue] = React.useState(0);

  // Custom CSS styling that will override theme styling
  const styleSet = {
    section: {
      fontFamily: 'libre baskerville',
      borderRadius: '0',
      width: '100vw',
    },
    heading: {
      fontWeight: 'bold',
      fontFamily: 'libre baskerville',
      fontSize: { xs: '1.6rem', sm: '2.5rem', md: '3rem' },
      textAlign: { xs: 'center', sm: 'left', md: 'left' },
    },
    preSalutationTypography: {
      fontSize: '1.4rem',
      fontFamily: 'libre baskerville',
      color: 'primary',
      marginBottom: '1rem',
      textAlign: { xs: 'center', sm: 'left', md: 'left' },
    },
    rolesDescription: {
      fontSize: { xs: '0.75rem', sm: '0.9rem', md: '1.1rem' },
      fontFamily: 'libre baskerville',
      marginBottom: '1rem',
      textAlign: { xs: 'center', sm: 'left', md: 'left' },
    },
    tabHeading: {
      fontFamily: 'sora',
      maxWidth: '148px',
      fontSize: {
        xs: '0.6rem',
        sm: '0.7rem',
        md: '0.8rem',
      },
      p: { xs: 0.9, md: 2 },
    },
    orgName: {
      color: theme.palette.primary.main,
      pb: {
        xs: '0rem',
        sm: '0rem',
        md: '0rem',
      },
      fontSize: {
        xs: '1.2rem',
        sm: '1.4rem',
        md: '1.6rem',
      },
      fontWeight: {
        xs: '100',
        sm: '300',
        md: '700',
      },
    },
    jobDomain: {
      pb: {
        xs: '1rem',
      },
      fontSize: {
        xs: '0.8rem',
        sm: '0.8rem',
        md: '0.9rem',
      },
      fontWeight: {
        xs: '100',
        sm: '300',
        md: '400',
      },
      fontStyle: 'italic',
      color: theme.palette.text.extraLight,
      pt: 0,
    },

    descText: {
      width: '100%',
      fontSize: {
        xs: '0.8rem',
        sm: '0.9rem',
        md: '1rem',
      },
      fontWeight: {
        xs: '100',
        sm: '300',
        md: '400',
      },
      // fontStyle: 'italic',
      pt: '1rem',
      color: theme.palette.text.light,
    },

    descIcon: {
      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.6rem' },
      pt: '1rem',
      pr: '1rem',
      color: theme.palette.text.light,
    },

    cardActionArea: {
      borderRadius: 3,
      transition: '0.45s',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    card: {
      minWidth: 196,
      borderRadius: 3,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: `0 8px 16px 0
        .rotate(-20)
        .darken(0.2)
        .fade(0.5)`,
      },
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // References for Animation of specific components
  const refProfilePicAnim = useSpringRef(),
    refHeadingAnim = useSpringRef(),
    refSelfDescAnim = useSpringRef(),
    refFavTechAnim = useSpringRef();

  // create animation styles for each animation reference
  const styleHeadingAnim = useFadeInEffect(refHeadingAnim),
    styleSelfDescAnim = useFadeInEffect(refSelfDescAnim),
    styleFavTechAnim = useFadeInFromRightEffect(refFavTechAnim, 200, 0);

  // Chain selected animations
  useChain(
    startAnimation
      ? [refProfilePicAnim, refHeadingAnim, refSelfDescAnim, refFavTechAnim]
      : []
  );

  const tabHeaders = () => {
    let tabList = [];
    data.workExperience.forEach((experience, idx) => {
      tabList.push(
        <Tab
          key={experience.chronology}
          sx={styleSet.tabHeading}
          label={experience.role}
          {...a11yProps(idx)}
        />
      );
    });
    return tabList.reverse();
  };

  const tabContent = () => {
    let tabContentList = [];

    data.workExperience.forEach((experience, idx) => {
      let descList = [];

      // TODO: #18 Need to animate the content panels
      experience.description.forEach((bulletPoint, index) =>
        descList.push(
          <Grid key={'descItem' + index} container alignContent={'center'}>
            <Grid xs={1} sx={{ mr: 0 }} item>
              <Typography sx={{ textAlign: 'center', margin: 'auto' }}>
                <CheckIcon key={'descIcon_' + index} sx={styleSet.descIcon} />
              </Typography>
            </Grid>
            <Grid xs={11} item>
              <Typography key={'descList_' + index} sx={styleSet.descText}>
                {bulletPoint}
              </Typography>
            </Grid>
          </Grid>
        )
      );

      tabContentList.push(
        <TabPanel
          key={experience.chronology}
          value={value}
          index={data.workExperience.length - (idx + 1)}>
          <Typography sx={styleSet.orgName}>
            {experience.organisation}
          </Typography>
          <Typography sx={styleSet.jobDomain}>
            {experience.jobDomain} - {experience.homeBase}
          </Typography>
          {descList}
        </TabPanel>
      );
    });

    return tabContentList;
  };

  return (
    <section id='Work' sx={styleSet.section}>
      <Waypoint onEnter={() => setStartAnimation(true)} />
      <Grid container alignItems={'center'} justifyContent={'center'} style={{ minHeight: '100vh' }} spacing={1}>
        <Grid item xs={12} sm={12} md={11} lg={11}>
          <Grid container alignItems={'center'} justifyContent='center'>
            <Grid sx={{ p: { xs: 1, md: 2 } }} item xs={12} sm={9} xl={8}>
              <animated.div style={styleHeadingAnim}>
                <Typography sx={styleSet.heading}>
                  The roles I've played!
                </Typography>
              </animated.div>

              <animated.div style={styleSelfDescAnim}>
                <Typography sx={styleSet.rolesDescription}>
                  Since {data.careerOverview.careerStartYear}, I have had the
                  pleasure of working on multiple projects, through these roles:
                </Typography>
              </animated.div>
              <animated.div style={styleFavTechAnim}>
                <Box
                  sx={{
                    component: 'div',
                    flexGrow: 1,
                    // bgcolor: 'background.paper',
                    display: 'flex',
                    minHeight: 300,
                    m: 0,
                    mt: '1rem',
                  }}>
                  <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={value}
                    onChange={handleChange}
                    aria-label='Vertical tabs example'
                    sx={{
                      minWidth: {
                        xs: '96px',
                      },
                      borderRight: 1,
                      borderColor: 'divider',
                      m: 0,
                      p: 0,
                    }}>
                    {tabHeaders()}
                  </Tabs>
                  <Box sx={{ mr: 2, width: '90%' }}>{tabContent()}</Box>
                </Box>
              </animated.div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
}
