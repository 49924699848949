import Particles from 'react-tsparticles';
import { useTheme } from '@mui/material/styles';

export const ParticleAnimation = () => {
  const theme = useTheme();
  const particlesInit = (main) => {
    //console.log(main);
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    //console.log(container);
  };
  return (
    <Particles
      id='tsparticles'
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: theme.palette.background.default,
          },
          position: '50% 50%',
          repeat: 'no-repeat',
          size: 'cover',
        },
        fullScreen: {
          zIndex: 1,
        },
        interactivity: {
          events: {
            onClick: {
              mode: 'push',
            },
            onHover: {
              mode: 'grab',
              parallax: {
                enable: true,
                force: 60,
              },
            },
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40,
            },
            grab: {
              distance: 400,
            },
          },
        },
        particles: {
          color: {
            value: '#e65100',
          },
          links: {
            color: {
              value: theme.palette.primary.main,
            },
            distance: 150,
            enable: true,
            opacity: 0.4,
          },
          move: {
            attract: {
              rotate: {
                x: 600,
                y: 1200,
              },
            },
            enable: true,
            path: {},
            outModes: {
              bottom: 'out',
              left: 'out',
              right: 'out',
              top: 'out',
            },
            speed: 1,
            spin: {},
          },
          number: {
            density: {
              enable: true,
            },
            value: 30,
          },
          opacity: {
            random: {
              enable: true,
            },
            value: {
              min: 0.1,
              max: 0.5,
            },
            animation: {
              enable: true,
              speed: 1,
              minimumValue: 0.1,
            },
          },
          size: {
            random: {
              enable: true,
            },
            value: {
              min: 0.1,
              max: 4,
            },
            animation: {
              enable: true,
              speed: 20,
              minimumValue: 0.1,
            },
          },
          twinkle: {
            particles: {
              frequency: 0.01,
            },
          },
          wobble: {
            enable: true,
            speed: 10,
          },
        },
      }}
    />
  );
};
