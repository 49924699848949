import * as React from 'react';

// MATERIAL UI COMPONENTS
import { Paper, Box, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// THEMES

// CONTEXT
import { UserContext } from '../context/userContext';
// import { useTheme } from '@mui/styles';

export default function TechStack() {
  // Context
  // const theme = useTheme();
  const data = React.useContext(UserContext).data;

  // Styles
  const styleSet = {
    iconLibrary: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 1,
      '& > :not(style)': {
        m: 1,
        width: { xs: 32, sm: 48, md: 64 },
        minHeight: { xs: 32 },
      },
      bgcolor: 'transparent',
    },
    iconPaper: {
      width: { xs: 32, sm: 48, md: 64 },
      textAlign: 'center',
      padding: { xs: '0.2rem' },
      fontSize: { xs: '0.5rem', sm: '1rem', md: '1.2rem' },
    },
    stackIconCaption: {
      display: { xs: 'block', md: 'none' },
      textAlign: 'center',
      fontSize: { xs: '0.45rem', sm: '0.7rem', md: '0.8rem' },
    },
  };

  const TechIcons = () => {
    let techIconList = [];
    Object.keys(data.skillSet).forEach((techCategory, index) => {
      data.skillSet[techCategory].forEach((techItem, idx) => {
        techIconList.push(
          <Paper
            elevation={0}
            key={'techIcon_' + index + '_' + idx}
            sx={styleSet.iconPaper}>
            <Tooltip
              key={'techIcon_ToolTip_' + index + '_' + idx}
              title={techItem.technology}>
              <img
                width='100%'
                alt={techItem.technology}
                src={techItem.iconName}
              />
            </Tooltip>

            <Typography sx={styleSet.stackIconCaption}>
              {techItem.technology}
            </Typography>
          </Paper>
        );
      });
    });

    return techIconList;
  };

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      component='div'
      sx={styleSet.iconLibrary}>
      {TechIcons()}
    </Box>
  );
}
