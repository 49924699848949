import * as React from 'react';
import { Typography, Grid } from '@mui/material';

// REACT SPRING ANIMATION HOOKS
import { animated, useSpringRef, useChain } from 'react-spring';
import { useFadeInEffect } from '../animations/springAnimationHooks';

// CONTEXT
import { UserContext } from '../context/userContext';
import { useTheme } from '@mui/styles';

export default function IntroSlab() {
  const theme = useTheme();
  const data = React.useContext(UserContext).data;
  const styleSet = {
    section: {
      fontFamily: 'libre baskerville',
      borderRadius: '0',
      width: '100vw',
      minHeight: '100vh',
      margin: 0,
    },
    nameText: {
      fontFamily: 'libre baskerville',
      fontWeight: 700,
      color: theme.palette.text.main,
      fontSize: { xs: '3.8rem', sm: '5rem', md: '6rem' },
      textAlign: { xs: 'center', sm: 'left' },
    },
    preSalutationTypography: {
      marginBottom: { xs: '0rem', sm: '-1.2rem' },
      fontSize: '1.4rem',
      fontFamily: 'libre baskerville',
      textAlign: { xs: 'center', sm: 'left', md: 'left' },
    },
    postSalutationTypography: {
      marginTop: { xs: '0rem', sm: '-1.2rem' },
      fontSize: '1.4rem',
      fontFamily: 'libre baskerville',
      textAlign: { xs: 'center', sm: 'left', md: 'left' },
    },
  };

  // References for Animation of specific components
  const refGreetingAnim = useSpringRef(),
    refNameAnim = useSpringRef(),
    refSelfDescAnim = useSpringRef();

  // create animation styles for each animation reference
  const styleGreetingAnim = useFadeInEffect(refGreetingAnim),
    styleNameAnim = useFadeInEffect(refNameAnim),
    styleSelfDescriptionAnim = useFadeInEffect(refSelfDescAnim);

  // Chain selected animations
  useChain([refGreetingAnim, refNameAnim, refSelfDescAnim]);

  const secondaryDescription = () => {
    let componentTree = [];
    data.profile.personalDescriptionVerbs.forEach((verb, idx) => {
      if (idx === data.profile.personalDescriptionVerbs.length - 1) {
        componentTree.push(<span key={verb}>{verb + '. '}</span>);
      } else if (idx !== data.profile.personalDescriptionVerbs.length - 2) {
        componentTree.push(<span key={verb}>{verb + ', '}</span>);
      } else {
        componentTree.push(<span key={verb}>{verb + ' & a '}</span>);
      }
    });
    return componentTree;
  };

  return (
    <section id='Home' style={styleSet.section}>
      <Grid container alignItems={'center'} justifyContent="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={11} sm={10} md={8} lg={7}>
          <Grid item md={9}>
            <animated.div style={styleGreetingAnim}>
              <Typography sx={styleSet.preSalutationTypography}>
                Hi! My name is
              </Typography>
            </animated.div>

            <animated.div style={styleNameAnim}>
              <Typography sx={styleSet.nameText}>
                {data.profile.firstName}!
              </Typography>
            </animated.div>

            <animated.div style={styleSelfDescriptionAnim}>
              <Typography sx={styleSet.postSalutationTypography}>
                I am a {secondaryDescription()}
              </Typography>
            </animated.div>
          </Grid>
        </Grid>
      </Grid>
    </section >
  );
}
