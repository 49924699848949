import * as React from 'react';

// MATERIAL UI
import { Paper, Typography, Grid } from '@mui/material';
import { Chip } from '@mui/material';
import { CardActionArea, Card, CardMedia } from '@mui/material';

// REACT SPRING ANIMATION HOOKS
import { animated, useSpringRef, useChain } from 'react-spring';
import {
  useFadeInFromLeftEffect,
  useFadeInFromRightEffect,
  useFadeInEffect,
} from '../animations/springAnimationHooks';

import { Waypoint } from 'react-waypoint';

// ASSETS
import ProfilePic from '../assets/images/profilePic-1.png';

// CONTEXT
import { UserContext } from '../context/userContext';

// Custom CSS styling that will override theme styling
const styleSet = {
  section: {
    fontFamily: 'libre baskerville',
    borderRadius: '0',
    width: '100vw',
    height: '100vh',
    margin: 0,
  },
  heading: {
    fontWeight: 'bold',
    fontFamily: 'libre baskerville',
    fontSize: { xs: '1.6rem', sm: '2.5rem', md: '3rem' },
    textAlign: { xs: 'center', sm: 'left', md: 'left' },
  },
  preSalutationTypography: {
    fontSize: '1rem',
    fontFamily: 'Sora',
    color: 'primary',
    marginBottom: '1rem',
    textAlign: { xs: 'center', sm: 'left', md: 'left' },
  },
  selfDescription: {
    fontSize: { xs: '0.75rem', sm: '0.9rem', md: '1.1rem' },
    fontFamily: 'Sora',
    marginTop: '1rem',
    textAlign: { xs: 'center', sm: 'left', md: 'left' },
  },
  favTech: {
    fontSize: { xs: '0.6rem', sm: '0.8rem' },
    fontFamily: 'Sora',
    textAlign: { xs: 'center', sm: 'left', md: 'left' },
    marginRight: { xs: 0.5, sm: 1 },
    marginBottom: { xs: 0.5, sm: 1 },
    marginTop: { xs: 0.5, sm: 1 },
  },
  favTechPaper: {
    textAlign: { xs: 'center', sm: 'left', md: 'left' },
    background: 'transparent',
    boxShadow: 'none',
  },
  cardActionArea: {
    borderRadius: 3,
    transition: '0.45s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  card: {
    minWidth: 196,
    borderRadius: 3,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: `0 8px 16px 0
        .rotate(-20)
        .darken(0.2)
        .fade(0.5)`,
    },
  },
};

export default function AboutSlab() {
  // UserContext
  const data = React.useContext(UserContext).data;

  // State Hook for Animation Trigger
  const [startAnimation, setStartAnimation] = React.useState(false);

  // References for Animation of specific components
  const refProfilePicAnim = useSpringRef(),
    refHeadingAnim = useSpringRef(),
    refSelfDescAnim = useSpringRef(),
    refFavTechAnim = useSpringRef();

  // create animation styles for each animation reference
  const styleProfilePicAnim = useFadeInFromLeftEffect(
    refProfilePicAnim,
    1000,
    100
  ),
    styleHeadingAnim = useFadeInEffect(refHeadingAnim),
    styleSelfDescAnim = useFadeInEffect(refSelfDescAnim),
    styleFavTechAnim = useFadeInFromRightEffect(refFavTechAnim, 200, 0);

  // Chain selected animations
  useChain(
    startAnimation
      ? [refProfilePicAnim, refHeadingAnim, refSelfDescAnim, refFavTechAnim]
      : []
  );

  const selfDescription = () => {
    let descriptionSet = [];

    data.profile.selfDescription.forEach((item, idx) => {
      descriptionSet.push(
        <Typography key={item + idx} sx={styleSet.selfDescription}>
          {item}
        </Typography>
      );
    });

    return descriptionSet;
  };

  const favTech = data.resumeContext.favTechnologies.map((item, idx) => {
    return (
      <Chip
        key={item + idx}
        sx={styleSet.favTech}
        color='primary'
        label={item}
      />
    );
  });

  return (
    <section id='About' sx={styleSet.section}>
      <Waypoint onEnter={() => setStartAnimation(true)} />
      <Grid container alignItems={'center'} justifyContent={'center'} style={{ minHeight: '100vh' }} spacing={2}>
        <Grid item xs={12} sm={12} md={10}>
          <Grid container alignItems={'center'} justifyContent='center'>
            <Grid sx={{ p: 2, mt: 3 }} item xs={7} sm={8} md={4} lg={3}>
              <CardActionArea sx={styleSet.cardActionArea}>
                <animated.div style={styleProfilePicAnim}>
                  <Card sx={styleSet.card}>
                    <CardMedia
                      component='img'
                      alt={data.profile.fullName}
                      image={ProfilePic}
                    />
                  </Card>
                </animated.div>
              </CardActionArea>
            </Grid>
            <Grid sx={{ p: 2 }} item xs={12} sm={8} md={7} xl={6}>
              <Grid item xs={12}>
                <animated.div style={styleHeadingAnim}>
                  <Typography sx={styleSet.heading}>A bit about me!</Typography>
                  {selfDescription()}
                </animated.div>
              </Grid>
              <Grid item xs={12}>
                <animated.div style={styleSelfDescAnim}>
                  <Typography sx={styleSet.selfDescription}>
                    Here are some technologies I've been working on in recent
                    times:
                  </Typography>
                </animated.div>
                <animated.div style={styleFavTechAnim}>
                  <Paper sx={styleSet.favTechPaper}>{favTech}</Paper>
                </animated.div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
}
